import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export abstract class BaseApiService {

  constructor(private httpClient: HttpClient) { }

  private getBaseUrl(): string {
    return environment.apiURL.endsWith('/') ? environment.apiURL.substring(environment.apiURL.length - 1) : environment.apiURL;
  }

  protected get(relativeUrl: string, options?): Observable<any> {
    return this.httpClient.get(this.getBaseUrl() + relativeUrl, options);
  }

  protected post(relativeUrl: string, data: any): Observable<any> {
    return this.httpClient.post(this.getBaseUrl() + relativeUrl, data);
  }

  protected put(relativeUrl: string, data: any, options?): Observable<any> {
    return this.httpClient.put(this.getBaseUrl() + relativeUrl, data, options);
  }

  protected delete(relativeUrl: string): Observable<any> {
    return this.httpClient.delete(this.getBaseUrl() + relativeUrl);
  }

  protected upload(relativeUrl: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post(this.getBaseUrl() + relativeUrl, formData);
  }

}

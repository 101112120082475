import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthApiService } from 'app/services/auth-api.service';
import { map, tap } from 'rxjs/operators';
import { ActiveClient, ActiveEvent, AppService } from 'app/services/app.service';

@Injectable()
export class AuthService {
  token: string;
  userDetails = null;
  constructor(public router: Router,
    private activatedRoute: ActivatedRoute,
    private authApi: AuthApiService,
    private appService: AppService) {
    const user = localStorage.getItem('user');
    if (user == null || user.length === 0) {
      this.userDetails = null;
    }
    try {
      this.userDetails = JSON.parse(localStorage.getItem('user'));
      this.appService.setUser(this.userDetails);
    } catch (error) {
      this.userDetails = null;
    }
  }

  navigateToFirstPage() {
    const activeClientJson = localStorage.getItem('activeClient');
    if (activeClientJson) {
      const activeClient = JSON.parse(activeClientJson);
      this.router.navigate(['c', activeClient.id, 'dashboard']);
      return;
    }
    if (this.userDetails.superAdmin) {
      this.router.navigate(['clients']);
      return;
    }
    const uniqueClients = this.userDetails.roles.map(role => role.clientId).filter((x, i, a) => {
      return a.indexOf(x) === i;
    });
    const clients = this.userDetails.roles.filter(role => role.clientId);


    if (uniqueClients.length === 1) {
      this.appService.setActiveClient(new ActiveClient(clients[0].clientId, clients[0].clientName));
      const events = this.userDetails.roles.filter(role => role.eventId);
      if (events.length === 1) {
        this.appService.setActiveEvent(new ActiveEvent(events[0].eventId, events[0].eventName, events[0].clientId));
        this.router.navigate(['c', clients[0].clientId, 'dashboard']);
        return;
      }

      this.router.navigate(['c', clients[0].clientId, 'events']);
      return;
    }

    this.router.navigate(['clients']);
  }


  login(username: string, password: string) {
    return this.authApi.login({ username, password }).pipe(map(user => {
      this.userDetails = user.user;
      this.appService.setUser(user.user);
      localStorage.setItem('user', JSON.stringify(user.user));
      this.token = user.token;
      localStorage.setItem('token', user.token);
      localStorage.setItem('refreshToken', user.refreshToken);
      this.navigateToFirstPage();
      return user;
    }));
  }
  registerOTC(model) {
    return this.authApi.otc(model).pipe(map(user => {
      this.userDetails = user.user;
      this.appService.setUser(user.user);
      localStorage.setItem('user', JSON.stringify(user.user));
      this.token = user.token;
      localStorage.setItem('token', user.token);
      localStorage.setItem('refreshToken', user.refreshToken);
      this.navigateToFirstPage();
      return user;
    }));
  }

  refreshToken() {
    return this.authApi.refreshToken(localStorage.getItem('refreshToken'))
      .pipe(tap(user => {
        this.userDetails = user.user;
        this.appService.setUser(user.user);
        this.token = user.token;
        localStorage.setItem('user', JSON.stringify(user.user));
        localStorage.setItem('token', user.token);
        localStorage.setItem('refreshToken', user.refreshToken);
      }, err => {
        this.logout();
      }));
  }

  logout() {
    this.userDetails = null;
    localStorage.removeItem('user');
    this.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('activeClient');
    localStorage.removeItem('activeEvent');
    this.appService.setActiveClient(null);
    this.appService.setUser(null);
    this.router.navigate(['ext', 'login']);
  }

  isAuthenticated() {
    return this.userDetails != null;
  }
}
function List<T>() {
  throw new Error('Function not implemented.');
}


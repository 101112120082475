import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';


export enum urls {
  login = '/auth/login',
  otc = '/auth/register/otc',
  refreshToken = '/auth/refresh-token'
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends BaseApiService {

  constructor(private http: HttpClient) {
    super(http);
  }

  login(credentials) {
    return this.post(urls.login, credentials);
  }

  otc(credentials) {
    return this.post(urls.otc, credentials);
  }

  refreshToken(refreshToken) {
    return this.post(urls.refreshToken, {
      refreshToken: refreshToken
    });
  }
}

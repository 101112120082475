import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HROUTES } from './navigation-routes.config';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from 'app/services/app.service';
import { MenuService } from 'app/services/menu.service';


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuItems: any[];
  public config: any = {};
  level = 0;
  transparentBGClass = "";
  menuPosition = 'Side';
  layoutSub: Subscription;
  activeClientSub: Subscription;
  activeClient = null;
  activeEventSub: Subscription;
  activeEvent = null;
  showEvent = false;


  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private menuService: MenuService,
    private appService: AppService) {
    this.config = this.configService.templateConf;
    this.activeClientSub = this.appService.activeClient$.subscribe((client) => {
      this.activeClient = client;
      if (client) {
        this.menuItems = menuService.getMenuRouteInfo(this.activeClient, this.activeEvent);
      }
    });
    this.activeEventSub = this.appService.activeEvent$.subscribe((event) => {
      this.activeEvent = event;
      this.menuItems = menuService.getMenuRouteInfo(this.activeClient, this.activeEvent);
    });
  }

  ngOnInit() {
    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
    })
  }

  ngAfterViewInit() {
  }

  loadLayout() {
    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== "") {
      this.menuPosition = this.config.layout.menuPosition;
    }
    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = "";
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.activeClientSub) {
      this.activeClientSub.unsubscribe();
    }
    if (this.activeEventSub) {
      this.activeEventSub.unsubscribe();
    }
  }

}

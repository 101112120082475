import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private user = new BehaviorSubject(null);
  user$ = this.user.asObservable();
  private activeClient = new BehaviorSubject<ActiveClient>(null);
  activeClient$ = this.activeClient.asObservable();
  private activeEvent = new BehaviorSubject<ActiveEvent>(null);
  activeEvent$ = this.activeEvent.asObservable();

  constructor() {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      this.setUser(JSON.parse(userJson));
    }

    const activeClientJson = localStorage.getItem('activeClient');
    const activeEventJson = localStorage.getItem('activeEvent');
    if (activeClientJson) {
      this.setActiveClient(JSON.parse(activeClientJson));
    }
    if (activeEventJson) {
      this.setActiveEvent(JSON.parse(activeEventJson));
    }
  }


  setActiveClient(client: ActiveClient) {
    if (client != null) {
      this.setActiveClientRoles(client);
      this.setActiveEvent(null);
      localStorage.setItem('activeClient', JSON.stringify(client));
    }
    this.activeClient.next(client);
  }

  setActiveEvent(event: ActiveEvent) {
    if (event != null) {
      this.setActiveEventRoles(event);
      localStorage.setItem('activeEvent', JSON.stringify(event));
    } else {
      localStorage.removeItem('activeEvent');
    }
    this.activeEvent.next(event);
  }

  setActiveClientRoles(client: ActiveClient) {
    const user = this.user.value;
    client.superAdmin = user.superAdmin;

    const clientRoles = user.roles.filter((clientRole) => {
      return clientRole.clientId === client.id && clientRole.roleContext === 'Client';
    });
    client.admin = clientRoles.filter((clientRole) => clientRole.role === "Admin").length > 0;
    client.importer = clientRoles.filter((clientRole) => clientRole.role === "Importer").length > 0;
    client.moderator = clientRoles.filter((clientRole) => clientRole.role === "Moderator").length > 0;
  }

  setActiveEventRoles(event: ActiveEvent) {
    const user = this.user.value;
    if (user == null) { return; }

    event.superAdmin = user.superAdmin === true;

    const clientRoles = user.roles.filter((clientRole) => {
      return clientRole.clientId === event.clientId && clientRole.roleContext === 'Client';
    });
    event.admin = clientRoles.filter((clientRole) => clientRole.role === "Admin").length > 0;
    event.importer = clientRoles.filter((clientRole) => clientRole.role === "Importer").length > 0;
    event.moderator = clientRoles.filter((clientRole) => clientRole.role === "Moderator").length > 0;
    const eventRoles = user.roles.filter((eventRole) => {
      return eventRole.eventId === event.id && eventRole.roleContext === 'Event';
    });
    event.admin = user.superAdmin || event.admin || eventRoles.filter((eventRole) => eventRole.role === "Admin").length > 0;
    event.importer = user.superAdmin || event.admin || event.importer ||
      eventRoles.filter((eventRole) => eventRole.role === "Importer").length > 0;
    event.moderator = user.superAdmin || event.admin || event.moderator
      || eventRoles.filter((eventRole) => eventRole.role === "Moderator").length > 0;
    event.speaker = eventRoles.filter((eventRole) => eventRole.eventId === event.id && eventRole.role === "Speaker").length > 0;
    event.exhibitor = eventRoles.filter((eventRole) => eventRole.eventId === event.id && eventRole.role === "Exhibitor").length > 0;
  }

  setUser(user) {
    this.user.next(user);
  }
}

export class ActiveClient {
  id: number;
  name: string;
  admin: boolean;
  importer: boolean;
  moderator: boolean;
  superAdmin: boolean;

  public constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

}

export class ActiveEvent {
  id: number;
  name: string;
  clientId: number;
  admin: boolean;
  importer: boolean;
  moderator: boolean;
  superAdmin: boolean;
  speaker: boolean;
  exhibitor: boolean;

  public constructor(id: number, name: string, clientId: number) {
    this.id = id;
    this.name = name;
  }
}
